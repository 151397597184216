import axios from "axios";
import { header } from "../utils/header";
import { updateHospital } from "../utils/types";
import { baseURL } from "../utils/constants";

export const getResourcesByHospitalId= async (hospital_id: string) => {
    try {
      const res = await axios.get(`${baseURL}/resource/${hospital_id}`);
      return res;
    } catch (error: any) {
      if (error.response || error.response.status === 403) {
        return error.response;
      } else {
        console.error(error);
      }
    }
  };