import React, { useEffect, useState } from "react";
import { Logo } from "../assets/icons/Icons";
import { useUserData } from "../lib/contexts/UserContext";
import HospitalCard from "../components/HospitalCard";
import Loader from "../components/Loader";
import { useHospDocData } from "../lib/contexts/HospitalDoctorContext";
import { useNavigate } from "react-router-dom";
import ProfilePicture from "../components/ProfilePicture";
import { scanQueueClinincs } from "../lib/utils/constants";
import LanguageSelector from "../components/LanguageSeelctor";
import { getAudioLanguageFromLocalStorage } from "../lib/utils/speechUtils";


// ✅ Define the expected structure of doctor objects
interface Doctor {
  doctor_id: string;
  profile_picture: string;
  full_name: string;
}

interface ChooseViewProps {
  setToggleStates: React.Dispatch<React.SetStateAction<boolean>>;
}

const ChooseView: React.FC<ChooseViewProps> = ({ setToggleStates }) => {
  const { userData } = useUserData();
  const { hospitalID, hospData, allDoctorsData = [], setDoctors } = useHospDocData(); // ✅ Ensure default value for allDoctorsData
  const navigate = useNavigate();

  // ✅ Persist toggle state using localStorage
  const [isToggled, setIsToggled] = useState<boolean>(() => {
    return localStorage.getItem("adsToggle") === "true";
  });

  // ✅ Sync toggle state when component mounts
  useEffect(() => {
    setToggleStates(isToggled);
  }, [isToggled, setToggleStates]);

  // ✅ Function to handle toggle
  const handleToggle = () => {
    const newToggleState = !isToggled;
    setIsToggled(newToggleState);
    setToggleStates(newToggleState);
    localStorage.setItem("adsToggle", newToggleState.toString());
  };

  return (
    <div className="flex relative flex-col w-full justify-center items-center pt-14 pb-40">
      <Logo />
      <div className="absolute top-5 right-5 flex">
      <h1 className="mt-[6px] mr-3 font-bold text-lg">ADS</h1>
      <label className="relative inline-flex items-center cursor-pointer mr-4">
          <input
            type="checkbox"
            className="sr-only peer"
            checked={isToggled}
            onChange={handleToggle}
          />
          <div className="group peer bg-white rounded-full duration-300 w-14 h-6 ring-2 ring-red-500 after:duration-300 after:bg-red-500 peer-checked:after:bg-lime-500 peer-checked:ring-lime-500 after:rounded-full after:absolute after:h-4 after:w-4 after:top-[12.5px] after:left-1 after:flex after:justify-center after:items-center peer-checked:after:translate-x-8 peer-hover:after:scale-95" />
        </label>
        <LanguageSelector
          selectedLanguage={getAudioLanguageFromLocalStorage()}
          onLanguageChange={() => {}}
        />
      </div>

      <div className="flex flex-col items-center space-y-3 mt-20 mb-16">
        <p className="font-semibold text-dark text-lg md:text-2xl lg:text-3xl">
          Choose a View
        </p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-7 mb-16">
        {/* Create constant */}
        {hospitalID === "fa577fb4-6353-44ae-9a41-0d362d0ab5ce"
          ? allDoctorsData?.slice(0, 2).map((doctor) => (
              <HospitalCard
                key={doctor.doctor_id}
                logo={doctor.profile_picture}
                name={doctor.full_name}
                docCount={"none"}
                handlePress={() => {
                  console.log(doctor.full_name);
                  setDoctors([doctor]);
                  navigate("/" + hospitalID + "/carousel-view");
                }}
              />
            ))
          : allDoctorsData?.map((doctor) => (
              <HospitalCard
                key={doctor.doctor_id}
                logo={doctor.profile_picture}
                name={doctor.full_name}
                docCount={"none"}
                handlePress={() => {
                  console.log(doctor.full_name);
                  setDoctors([doctor]);
                  navigate("/" + hospitalID + "/carousel-view");
                }}
              />
            ))}
      </div>
      <div>
        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-7 mb-16">
          {allDoctorsData && allDoctorsData.length > 1 && (
            <>
              <button
                className="min-w-[320px] md:min-w-[360px] shadow-md rounded-lg hover:opacity-70"
                onClick={() => {
                  setDoctors(
                    hospitalID === "fa577fb4-6353-44ae-9a41-0d362d0ab5ce"
                      ? allDoctorsData?.slice(0, 2)
                      : allDoctorsData
                  );
                  navigate("/" + hospitalID + "/carousel-view");
                }}
              >
                <div className="flex flex-row items-center bg-white p-5 rounded-lg border-b-[1px] border-doctorsBorder">
                  {hospData?.logo === "test" ? (
                    <ProfilePicture
                      username={hospData.hospital_name}
                      className="w-14 h-14"
                    />
                  ) : (
                    <img
                      className={`w-14 h-14 rounded-full`}
                      src={hospData?.logo}
                      alt="hospital-logo"
                    ></img>
                  )}
                  <p className="font-semibold text-sbTextHover text-sm md:text-base ml-4">
                    Carousel View
                  </p>
                </div>
              </button>
              <button
                className="min-w-[320px] md:min-w-[360px] shadow-md rounded-lg hover:opacity-70"
                onClick={() => navigate("/" + hospitalID + "/choose-doctors")}
              >
                <div className="flex flex-row items-center bg-white p-5 rounded-lg border-b-[1px] border-doctorsBorder">
                  {hospData?.logo === "test" ? (
                    <ProfilePicture
                      username={hospData.hospital_name}
                      className="w-14 h-14"
                    />
                  ) : (
                    <img
                      className={`w-14 h-14 rounded-full`}
                      src={hospData?.logo}
                      alt="hospital-logo"
                    ></img>
                  )}
                  <p className="font-semibold text-sbTextHover text-sm md:text-base ml-4">
                    Column View
                  </p>
                </div>
              </button>
            </>
          )}
        </div>
      </div>

      {userData === undefined && <Loader />}
    </div>
  );
};

export default ChooseView;
