import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Signin from "./screens/Signin";
import Carousel from "./screens/Carousel";
import ChooseHosp from "./screens/ChooseHosp";
import ChooseView from "./screens/ChooseView";
import ColumnView from "./screens/ColumnView";
import ChooseDoctors from "./screens/ChooseDoctors";
import ChooseDoctor from "./screens/ChooseDoctor";
import ClinicAndScanView from "./screens/ClinicAndScanView";
import ScanView from "./screens/ScanView";

const AppRoutes: React.FC = () => {
  const [toggleState, setToggleState] = useState<boolean>(() => {
    return localStorage.getItem("adsToggle") === "true";
  });

  // ✅ Ensure toggle state syncs across refresh
  useEffect(() => {
    const savedState = localStorage.getItem("adsToggle") === "true";
    setToggleState(savedState);
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Signin />} />
      <Route path="/admin" element={<ChooseHosp />} />

      <Route
        path="/:hospital_id"
        element={<ChooseView setToggleStates={setToggleState} />}
      />
      <Route path="/:hospital_id/choose-doctors" element={<ChooseDoctors />} />

      <Route
        path="/:hospital_id/carousel-view"
        element={<Carousel isToggled={toggleState} />}
      />
      <Route path="/:hospital_id/column-view" element={<ColumnView />} />
      <Route path="/:hospital_id/choose-doctor" element={<ChooseDoctor />} />
      {/* <Route path="/:hospital_id/clinic-and-Scan-view" element={<ClinicAndScanView/>} /> */}
      {/* <Route path="/:hospital_id/scan-view" element={<ScanView/>} /> */}
    </Routes>
  );
};

export default AppRoutes;
