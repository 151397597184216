import React from "react";
import { capitalizeName } from "../lib/utils/funcs";

const Patient = ({
  pos,
  name,
  notStarted,
  onGoing,
  empty,
  text,
  type,
  patientKey,
  carousel,
  status,
  isToggled = false, // ✅ Accept status as a prop
}: {
  pos?: any;
  name?: string;
  notStarted?: boolean;
  onGoing?: boolean;
  empty?: boolean;
  text?: string;
  queue_type?: string;
  type?: string;
  patientKey?: number;
  carousel?: boolean;
  status?: number; // ✅ Ensure status is defined
  isToggled?: boolean;
}) => {
  console.log(isToggled + '✅');
  if (carousel === undefined) {
    carousel = true;
  }

  let bookingOrRoomText 
  if(status === 8 || status === 9){
    bookingOrRoomText = status === 8 ? "Room 1" : "Room 2"
  }else{
    bookingOrRoomText  = type === "booking" ? "Booking" : "Walk-in"
  }

  if (notStarted) {
    return (
      <div className="bg-darkBlue p-5 my-5 mx-36 rounded-xl">
        <div className="rounded-[1rem]">
          <p className="text-white text-xl">Queue has not started</p>
        </div>
      </div>
    );
  }

  if (onGoing) {
    return (
      <div
        className={
          pos
            ? "flex flex-row bg-[#EBEBEB] mt-3 h-20  p-2 justify-center  items-center rounded-full"
            : "flex flex-row bg-[#EBEBEB] mt-3 h-[10vh] p-2 justify-center  border-2 border-[#10B018] items-center rounded-full"
        }
      >
        {pos && (
          <p className="flex text-2xl bg-[#10B018] text-white h-16 w-16 rounded-full justify-center items-center">
            {pos}
          </p>
        )}
        <p className="text-2xl flex-[2.8] ml-4 font-medium uppercase">
          {capitalizeName(name || "")}
        </p>

        {/* ✅ Check status and display appropriate text */}
        {status === 8 ? (
          <p className={`text-md bg-black py-2 text-white rounded-full justify-center text-center mr-4 ${isToggled?"flex-[0.6]":"flex-[0.4]"}`}>
            Room 1 
          </p>
        ) : status === 9 ? (
          <p className={`text-md bg-black py-2 text-white rounded-full justify-center text-center mr-4 ${isToggled?"flex-[0.6]":"flex-[0.4]"}`}>
            Room 2
          </p>
        ) : (
          <p
            className={`text-md bg-black py-2 text-white rounded-full justify-center text-center mr-4 ${isToggled?"flex-[0.6]":"flex-[0.4]"}`}
          >
            {type === "booking" ? "Booking" : "Walk-in"}
          </p>
        )}
      </div>
    );
  }

  if (empty) {
    return (
      <div className="flex flex-col justify-center items-center bg-white rounded-lg h-full mx-[20%] p-5">
        <img
          src={require("../assets/images/empty.png")}
          alt="Queue empty"
          className={(carousel || !isToggled )? "!w-[100%]" : "!w-[100%]"}
        />
        <p className="text-center mb-5 font-medium">{text}</p>
      </div>
    );
  }

  return (
    <div
      className={`flex flex-row bg-[#EBEBEB] mt-3 h-20 p-2 justify-center items-center rounded-full`}
    >
      {pos && (
        <p className="flex text-2xl bg-[#24222026] text-black h-16 w-16 rounded-full justify-center items-center">
          {pos}
        </p>
      )}
      <p className="text-2xl flex-[2.8] ml-4 font-medium uppercase">
        {capitalizeName(name || "")}
      </p>
      <p
        className={`text-md bg-black py-2 text-white rounded-full justify-center text-center mr-4 ${isToggled?"flex-[0.6]":"flex-[0.4]"}`}
      >
        {type === "booking" ? "Booking" : "Walk-in" }
      </p>
    </div>
  );
};

export default Patient;
