import React, { useState } from "react";
import { HiOutlineSpeakerWave } from "react-icons/hi2"; // Audio icon from react-icons

interface LanguageSelectorProps {
  selectedLanguage: string;
  onLanguageChange: (language: string) => void;
}

const LanguageSelector: React.FC<LanguageSelectorProps> = ({
  selectedLanguage,
  onLanguageChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [language, setLanguage] = useState(selectedLanguage);

  const languages = [
    { value: "en", label: "English" },
    { value: "kn", label: "Kannada" },
    { value: "hi", label: "Hindi" },
    { value: "ta", label: "Tamil" },
    { value: "te", label: "Telugu" },
  ];

  const handleLanguageChange = (languageValue: string) => {
    const selected = languages.find((lang) => lang.value === languageValue);
    if (selected) {
      setLanguage(languageValue);
      onLanguageChange(languageValue);
      localStorage.setItem("audioLanguage", languageValue);
      setIsOpen(false);
    }
  };

  return (
    <div className="relative inline-block">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center px-4 py-2 w-36 text-gray-700 bg-white border border-gray-300 rounded-lg shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2"
      >
        <HiOutlineSpeakerWave className="mr-2 text-blue-500" size={20} />
        <span className="capitalize">{languages.find((lang) => lang.value === language)?.label}</span>
      </button>

      {isOpen && (
        <ul className="absolute right-0 top-9 z-10 w-36 mt-2 bg-white border border-gray-300 rounded-lg shadow-md">
          {languages.map((lang) => (
            <li
              key={lang.value}
              onClick={() => handleLanguageChange(lang.value)}
              className="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100"
            >
              <span className="capitalize">{lang.label}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default LanguageSelector;



// import React, { useState } from 'react';

// interface LanguageSelectorProps {
//   selectedLanguage: string;
//   onLanguageChange: (language: string) => void;
// }

// const LanguageSelector: React.FC<LanguageSelectorProps> = ({ selectedLanguage, onLanguageChange }) => {
//    const [language, setLanguage] =  useState(selectedLanguage)
//   return (
//     <select className='w-28' value={language} onChange={(e) => {
//         onLanguageChange(e.target.value)
//         localStorage.setItem("audioLanguage", e.target.value)
//         setLanguage(e.target.value)
//     }}>
//       <option value="en">English</option>
//       <option value="kn">Kannada</option>
//       <option value="hi">Hindi</option>
//       <option value="ta">Tamil</option>
//       <option value="te">Telugu</option>
//     </select>
//   );
// };

// export default LanguageSelector;
