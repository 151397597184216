import React, { useEffect, useState } from "react";
import moment from "moment";
import QueueSlide from "../components/QueueSlide";
import Loader from "../components/Loader";
import { useUserData } from "../lib/contexts/UserContext";
import { useHospDocData } from "../lib/contexts/HospitalDoctorContext";
import { getResourcesByHospitalId } from "../lib/apis/resources";
import { ResourcesType } from "../lib/utils/types";
import { useNavigate } from "react-router-dom";
import ScrollingText from "../components/ScrollingText";
import { useInterval } from "../lib/utils/useInterval";
import { Carousel } from "react-responsive-carousel";
import { scanQueueClinincs } from "../lib/utils/constants";
import QueueSlideWithRooms from "../components/QueueSlideWithRooms";

interface CarouselScreenProps {
  isToggled: boolean;
}


const CarouselScreen: React.FC<CarouselScreenProps> = ({ isToggled }) => {
  const { userData } = useUserData();
  const { hospitalID, doctors } = useHospDocData();
  const navigate = useNavigate();

  const [resources, setResources] = useState<ResourcesType[]>([]);
  const [currentResourceIndex, setCurrentResourceIndex] = useState<number>(0);
  const [time, setTime] = useState(moment().format("hh:mm:ss A"));

  // Fetch resources from API
  const fetchResources = async () => {
    try {
      const response = await getResourcesByHospitalId(String(hospitalID));
      if (response.data.status === "SUCCESS") {
        setResources(response.data.result);
      }
    } catch (error) {
      console.error("Error fetching resources:", error);
    }
  };

  // Fetch resources on component mount
  useEffect(() => {
    fetchResources();
  }, []);
  // console.log("doctors", doctors);

  // Update time every second
  useInterval(() => {
    setTime(moment().format("hh:mm:ss A"));
  }, 1000);

  // Navigate to admin if doctors data is missing
  useEffect(() => {
    if (!doctors) navigate("/" + hospitalID);
  }, [doctors, navigate, hospitalID]);

  // Handle automatic switching of resources
  useEffect(() => {
    if (resources.length > 0) {
      const currentResource = resources[currentResourceIndex];

      if (currentResource?.category === "image") {
        const timer = setTimeout(() => {
          setCurrentResourceIndex(
            (prevIndex) => (prevIndex + 1) % resources.length
          );
        }, 5000);
        return () => clearTimeout(timer);
      }
    }
  }, [currentResourceIndex, resources]);

  // Handle video end event
  const handleVideoEnd = () => {
    if (resources.length > 0) {
      setCurrentResourceIndex(
        (prevIndex) => (prevIndex + 1) % resources.length
      );
    }
  };
  console.log(doctors);

  // JSX Render
  return (
    <>
      {userData ? (
        <div className="flex flex-col md:flex-row">
          {doctors?.length === 1 && (
            <>
              <div className={isToggled?"md:w-[50%] h-full p-4 ":"md:w-[100%] px-10 h-full p-4 "}>
                <div className="flex justify-between items-center">
                  <p className="flex  text-2xl ">{time}</p>
                  <img
                    src={require("../assets/images/DTlogo.png")}
                    alt="Queue empty"
                    className={`flex w-[8rem] h-[3rem]  ${isToggled?"left-[90%] fixed top-[15px] cursor-pointer z-40":""}`}
                    onClick={() => navigate("/admin")}
                  />
                </div>
                {doctors?.map((doc, index) =>
                  hospitalID && scanQueueClinincs.includes(hospitalID) ? (
                    <QueueSlideWithRooms
                      key={index}
                      mapping_id={doc.mapping_id}
                      carouselView={false}
                      isToggled={isToggled}
                    />
                  ) : (
                    <QueueSlide
                      key={index}
                      mapping_id={doc.mapping_id}
                      carouselView={false}
                      isToggled={isToggled}
                    />
                  )
                )}
              </div>

              {/* Right Section (Carousel) */}
              {isToggled && (
                <div className="md:w-[50%] h-full md:h-[100vh] flex items-center sticky top-0">
                  <div className="h-full w-full flex justify-center items-center">
                    {resources.length > 0 ? (
                      resources[currentResourceIndex]?.category === "video" ? (
                        <video
                          src={resources[currentResourceIndex]?.resource_url}
                          autoPlay
                          loop={false}
                          className="w-full h-full object-cover"
                          onEnded={handleVideoEnd}
                        />
                      ) : (
                        <img
                          src={resources[currentResourceIndex]?.resource_url}
                          alt={`Resource ${resources[currentResourceIndex]?.resource_id}`}
                          className="w-full h-full object-cover"
                        />
                      )
                    ) : (
                      <video
                        src="https://ik.imagekit.io/gn9by35ed/Button%20(Landscape)%20(1).webm/ik-video.mp4?updatedAt=1734682888467"
                        autoPlay
                        loop
                        muted
                      />
                    )}
                  </div>
                </div>
              )}
            </>
          )}
          {doctors && doctors?.length > 1 && (
            <>
              <div className="md:w-full h-full p-4">
                <div className="flex justify-between items-center m-auto w-[98%]">
                  <p className="flex  text-2xl ">{time}</p>
                  <img
                    src={require("../assets/images/DTlogo.png")}
                    alt="Queue empty"
                    className="flex w-[8rem] h-[3rem] cursor-pointer z-40"
                    onClick={() => navigate("/admin")}
                  />
                </div>
                <Carousel
                  autoPlay
                  infiniteLoop
                  interval={5000}
                  showThumbs={false}
                  showStatus={false}
                  showIndicators={false}
                  stopOnHover={false}
                >
                  {doctors?.map((doc, index) =>
                  hospitalID && scanQueueClinincs.includes(hospitalID) ? (
                    <QueueSlideWithRooms
                      key={index}
                      mapping_id={doc.mapping_id}
                      carouselView={true}
                      isToggled={isToggled}
                    />
                  ) : (
                    <QueueSlide
                      key={index}
                      mapping_id={doc.mapping_id}
                      carouselView={true}
                      isToggled={isToggled}
                    />
                  )
                )}
                </Carousel>
              </div>
            </>
          )}
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default CarouselScreen;
