import { useRef, useEffect } from 'react';
import { synthesyzeSpeech } from '../apis/speech';
import { getAudioLanguageFromLocalStorage } from '../utils/speechUtils';

const useSpeakNextPatientsName = (
  language: string = getAudioLanguageFromLocalStorage(),
  doctorName: string | undefined,
  patient1Name: string | undefined,
  patient1BookingId: string | undefined,
  patient2Name: string | undefined,
  patient2BookingId: string | undefined,
) => {
  const isPlayingRef = useRef<boolean>(false); // Track whether the audio is playing
  const audioRef = useRef<HTMLAudioElement | null>(null); // Track the audio element

  const patient1ToCall = JSON.stringify({
    name: patient1Name?.toLowerCase(),
    bookingId: patient1BookingId,
    lang: language,
  });
  const patient2ToCall = JSON.stringify({
    name: patient2Name?.toLowerCase(),
    bookingId: patient2BookingId,
    lang: language,
  });

  // Function to mute all playing videos
  const muteAllVideos = () => {
    document.querySelectorAll('video').forEach((video) => {
      if (!video.paused) {
        video.dataset.wasPlaying = 'true';
        video.muted = true;
      }
    });
  };

  // Function to unmute videos that were previously playing
  const unmuteAllVideos = () => {
    document.querySelectorAll('video').forEach((video) => {
      if (video.dataset.wasPlaying === 'true') {
        video.muted = false;
        delete video.dataset.wasPlaying;
      }
    });
  };

  async function playAudio(audioContent: string) {
    const audio = new Audio(`data:audio/mp3;base64,${audioContent}`);

    if (audio) {
      audioRef.current = audio;
      isPlayingRef.current = true;

      // Mute all videos before playing
      muteAllVideos();

      try {
        await audio.play();
        await new Promise((resolve) =>
          audio.addEventListener('ended', resolve, { once: true })
        );
        await audio.play();
        await new Promise((resolve) =>
          audio.addEventListener('ended', resolve, { once: true })
        );
      } catch (error) {
        console.error('Error playing audio:', error);
      } finally {
        isPlayingRef.current = false;
        // Unmute videos after speaking
        unmuteAllVideos();
      }
    }
  }

  async function playAudios(audioContent1: string, audioContent2: string) {
    const audio1 = new Audio(`data:audio/mp3;base64,${audioContent1}`);
    const audio2 = new Audio(`data:audio/mp3;base64,${audioContent2}`);

    if (audio1 || audio2) {
      // Mute all videos before playing
      muteAllVideos();

      try {
        if (audio1) {
          audioRef.current = audio1;
          isPlayingRef.current = true;
          await audio1.play();
          await new Promise((resolve) =>
            audio1.addEventListener('ended', resolve, { once: true })
          );
          await audio1.play();
          await new Promise((resolve) =>
            audio1.addEventListener('ended', resolve, { once: true })
          );
        }

        if (audio2) {
          audioRef.current = audio2;
          isPlayingRef.current = true;
          await audio2.play();
          await new Promise((resolve) =>
            audio2.addEventListener('ended', resolve, { once: true })
          );
          await audio2.play();
          await new Promise((resolve) =>
            audio2.addEventListener('ended', resolve, { once: true })
          );
        }
      } catch (error) {
        console.error('Error playing audio:', error);
      } finally {
        isPlayingRef.current = false;
        // Unmute videos after speaking
        unmuteAllVideos();
      }
    }
  }

  const getRoomName = (room: string) => {
    switch (room) {
      case 'room1':
        return 'room one';
      case 'room2':
        return 'room two';
      default:
        return '';
    }
  };

  const speakPatientName = async (
    patientName: string,
    patientBookingId: string | undefined,
    patientRoom: string
  ) => {
    const patientInRoom1 = JSON.parse(localStorage.getItem('patientInRoom1') || '{}');
    const patientInRoom2 = JSON.parse(localStorage.getItem('patientInRoom2') || '{}');

    if (
      ((patientRoom === 'room1' && patientInRoom1 === patient1ToCall) ||
        (patientRoom === 'room2' && patientInRoom2 === patient2ToCall)) &&
      isPlayingRef.current
    ) {
      return;
    }

    if (isPlayingRef.current && audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      audioRef.current = null;
      isPlayingRef.current = false;
    }

    const res = await synthesyzeSpeech(
      language,
      doctorName?.toLowerCase(),
      patientName?.toLowerCase(),
      patientBookingId,
      getRoomName(patientRoom)
    );

    if (res?.status === 200) {
      const audioContent = res?.data?.result;
      if (audioContent) {
        await playAudio(audioContent);
      }
    } else {
      console.error('Error in generating audio');
    }
  };

  const speakPatientsName = async (
    patient1: string | undefined,
    patient1BookingId: string | undefined,
    patient2: string | undefined,
    patient2BookingId: string | undefined
  ) => {
    if (!patient1 && !patient2) return;

    const res1 = await synthesyzeSpeech(
      language,
      doctorName?.toLowerCase(),
      patient1?.toLowerCase(),
      patient1BookingId,
      getRoomName('room1')
    );

    const res2 = await synthesyzeSpeech(
      language,
      doctorName?.toLowerCase(),
      patient2?.toLowerCase(),
      patient2BookingId,
      getRoomName('room2')
    );

    if (res1?.status === 200 && res2?.status === 200) {
      await playAudios(res1.data.result, res2.data.result);
    } else {
      console.error('Error in generating audio');
    }
  };

  const initiateSpeak = async () => {
    const patientInRoom1 = localStorage.getItem('patientInRoom1') || '{}';
    const patientInRoom2 = localStorage.getItem('patientInRoom2') || '{}';

    if (patientInRoom1 !== patient1ToCall && patientInRoom2 !== patient2ToCall) {
      await speakPatientsName(patient1Name, patient1BookingId, patient2Name, patient2BookingId);
      localStorage.setItem('patientInRoom1', patient1ToCall);
      localStorage.setItem('patientInRoom2', patient2ToCall);
    } else if (patientInRoom1 !== patient1ToCall) {
      if (patient1Name) {
        await speakPatientName(patient1Name, patient1BookingId, 'room1');
        localStorage.setItem('patientInRoom1', patient1ToCall);
      }
    } else if (patientInRoom2 !== patient2ToCall) {
      if (patient2Name) {
        await speakPatientName(patient2Name, patient2BookingId, 'room2');
        localStorage.setItem('patientInRoom2', patient2ToCall);
      }
    }
  };

  useEffect(() => {
    if (!doctorName && !patient1Name && !patient2Name) return;
    initiateSpeak();
  }, [doctorName, patient1Name, patient2Name, patient1BookingId, patient2BookingId, language]);

  useEffect(() => {
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        audioRef.current = null;
        isPlayingRef.current = false;
      }
    };
  }, []);

};

export default useSpeakNextPatientsName;
