import axios from "axios";
import { baseURL } from "../utils/constants";
import { header } from "../utils/header";
import { getAudioLanguageFromLocalStorage } from "../utils/speechUtils";

const synthesyzeSpeechAPI = () => {
  let previousPatient: string | undefined = undefined;
  let previousDoctor: string | undefined = undefined;
  let cachedLanguage: string | undefined = undefined
  let previousBookingId: string | undefined = undefined;

  return async (language: string = getAudioLanguageFromLocalStorage(), doctor: string | undefined, patient: string | undefined, bookingId: string | undefined , room?: string) => {
    if (!doctor || !patient) {
      return;
    }

    const normalizedDoctor = doctor.toLowerCase();
    const normalizedPatient = patient.toLowerCase();

    // if function is again called while previous request is still in progress, return
     if (normalizedPatient === previousPatient && normalizedDoctor === previousDoctor && previousBookingId === bookingId  && language === cachedLanguage) {
      return;
    }

    // Update cache
    previousPatient = normalizedPatient;
    previousDoctor = normalizedDoctor;
    cachedLanguage = language
    previousBookingId = bookingId

    try {
      const response = await axios.post(`${baseURL}/textToSpeech/synthesize`, {
          language,
          doctor: normalizedDoctor,
          patient: normalizedPatient,
          ...(room && { room })
        },
        { headers: header() }
      );

      // Update cache
      previousPatient = normalizedPatient;
      previousDoctor = normalizedDoctor;
      cachedLanguage = language
      previousBookingId = bookingId

      return response;
    } catch (error: any) {
      if (error.response) {
        console.error(error.response);
        return error.response;
      } else {
        console.error(error);
      }
    }
  };
}

export const synthesyzeSpeech  =  synthesyzeSpeechAPI()