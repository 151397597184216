// BROWSER BASED SPEECH SYNTHESIS FUNCTION (USED BEFORE THE GOOGLE CLOUD TEXT TO SPEECH API)
// export const speakText = (text : string) => {
//   const utterance = new SpeechSynthesisUtterance(text);
//   utterance.rate = 0.85;
//   // Function to set a female voice
//   const setFemaleVoice = () => {
//     const voices = window.speechSynthesis.getVoices(); 
//     let femaleVoice;
//     // Try to find Indian accent first
//     femaleVoice = voices.find((voice) =>
//       voice.name.toLowerCase() === "Google हिन्दी".toLowerCase()
//     );
//     // As a backup, have some other accent
//     if (!femaleVoice) {
//       femaleVoice = voices.find((voice) =>
//         ["female", "woman", "girl", "samantha", "google us english"].some((keyword) => voice.name.toLowerCase().includes(keyword))
//       );
//     }
      
//     if (femaleVoice) {
//       utterance.voice = femaleVoice;
//     }
//     window.speechSynthesis.speak(utterance);
//   };
//   // Ensure voices are loaded before selecting
//   window.speechSynthesis.onvoiceschanged = setFemaleVoice;
//   if (window.speechSynthesis.getVoices().length > 0) {
//     setFemaleVoice();
//   }
// };

export const getAudioLanguageFromLocalStorage = () => {
  const language = localStorage.getItem("audioLanguage")
  if(language) return language
  localStorage.setItem ("audioLanguage", "en")
  return "en"
}

export const setAudioLanguageToLocalStorage = (language: string ='en') =>{
  localStorage.setItem("audioLanguage", language)
}