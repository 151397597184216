import { setCookie, deleteCookie } from '../utils/cookies';
import { hitRefreshToken } from '../apis/user';

export async function refreshAccessToken(accessToken: string, refreshToken: string) {
  const refresh_data = await hitRefreshToken(accessToken, refreshToken);
  if (refresh_data?.status === 200) {
    console.log("Refresh");
    setCookie("accessToken", refresh_data.data.result.access_token, 30);
    setCookie("refreshToken", refresh_data.data.result.refresh_token, 30);
    return true;
  } else {
    deleteCookie("accessToken");
    deleteCookie("refreshToken");
    deleteCookie("hospID");
    return false;
  }
}