import React from "react";
import { capitalizeName } from "../lib/utils/funcs";

const ColumnPatient = ({
  pos,
  name,
  notStarted,
  onGoing,
  empty,
  text,
  queue_type,
}: {
  pos?: any;
  name?: string;
  notStarted?: boolean;
  onGoing?: boolean;
  empty?: boolean;
  text?: string;
  queue_type?: string;
}) => {
  if (notStarted) {
    return (
      <div className="bg-darkBlue p-5 mt-5 rounded-xl w-full">
        <p className="text-white text-xl text-center">Queue has not started</p>
      </div>
    );
  }

  if (onGoing) {
    return (
      <div className="bg-green flex flex-row justify-center items-center p-5 mt-5 rounded-xl w-full">
        {pos && <p className="text-white flex-[0.4] text-3xl font-bold">Token {pos}</p>}
        <p className={`text-2xl flex-[0.6] font-medium text-white ${!pos && "text-center ml-0"}`}>
          {capitalizeName(name || "")}
        </p>
      </div>
    );
  }

  if (empty) {
    return (
      <div className="flex flex-col justify-center items-center bg-white rounded-lg mt-10 p-5 h-[75%]">
        <img
          src={require("../assets/images/empty.png")}
          alt="Queue empty"
          className="!w-[80%]"
        />
        <p className="text-center mb-5 font-medium">{text}</p>
      </div>
    );
  }

  return (
    <div className="bg-white flex flex-row justify-center items-center p-5 mt-5 rounded-xl w-full">
      {pos && <p className="text-primary flex-[0.4] text-3xl font-bold">Token {pos}</p>}
      <p className={`text-2xl flex-[0.6] font-medium ${!pos && "text-center ml-0"}`}>{capitalizeName(name || "")}</p>
    </div>
  );
};

export default ColumnPatient;
